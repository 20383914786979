export function addImboxChat () {
  const se = document.createElement('script')
  se.type = 'text/javascript'
  se.async = true
  se.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js'

  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(se, s)

  const style = document.createElement('style')
  style.type = 'text/css'
  style.appendChild(document.createTextNode(`
    #imbox-launcher-container628 > div[id^="zoid-imbox-launcher"] {
      z-index: 20 !important;
    }
  `))

  const st1 = document.getElementsByTagName('style')[0]
  st1.parentNode.insertBefore(style, st1)
}
