import { getProp } from '@ocp-zmarta/zmarta-cl'

export function getApplicationStatus (statuses, applicationStatus) {
  if (getProp(statuses, 'CANCELLED').find(status => status === applicationStatus.replace(/_/g, '-'))) {
    return 'CANCELLED'
  } else if (getProp(statuses, 'PAUSED').find(status => status === applicationStatus.replace(/_/g, '-'))) {
    return 'PAUSED'
  } else if (getProp(statuses, 'ONGOING').find(status => status === applicationStatus.replace(/_/g, '-')) ||
    new Date(getProp(applicationStatus, 'attributes', 'startDate')).getTime() >= Date.now()) {
    return 'ONGOING'
  } else {
    return 'HISTORIC'
  }
}

export function formatApplication (snapshot, receiptBaseUrl) {
  return {
    providerLogo: { url: getProp(snapshot, 'product', 'provider', 'logoUrl'), alt: getProp(snapshot, 'product', 'provider', 'name') },
    productType: getProp(snapshot, 'product', 'type'),
    commitmentPeriod: getProp(snapshot, 'product', 'commitmentPeriod'),
    validityPeriod: getProp(snapshot, 'product', 'validityPeriod'),
    cancellationPeriod: getProp(snapshot, 'product', 'cancellationPeriod'),
    totalKwhPrice: getProp(snapshot, 'product', 'pricing', 'totalKwhPriceInclVat'),
    receiptUrl: formatReceiptUrl(receiptBaseUrl, getProp(snapshot, 'applicants', '0', 'email'), getProp(snapshot, 'applicants', '0', 'personalIdentityNumber'), getProp(snapshot, 'id')),
    applicationStatus: getProp(snapshot, 'attributes', 'applicationStatus').replace(/_/g, '-'),
    address: getProp(snapshot, 'entities', 'property', 'address'),
    startDate: getProp(snapshot, 'attributes', 'startDate')?.split('T')[0] || getProp(snapshot, 'attributes', 'registrationDate')?.split('T')[0]
  }
}

function formatReceiptUrl (baseUrl, email, ssn, referenceNumber) {
  if (ssn.length > 12) {
    ssn = ssn.slice(0, 8)
  } else {
    ssn = ssn.slice(0, 6)
  }
  return `${baseUrl}&email=${email}&socialSecurityNumber=${ssn}&referenceNumber=${referenceNumber}`
}
