import { GraphQLClient, gql } from 'graphql-request'
import { getProp, isArray, isBool } from '@ocp-zmarta/zmarta-cl'
import { getApplicationStatus, formatApplication } from '@utils/applications'
import { isNotEmpty } from '@utils'
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    loading: true,
    hasApplications: false,
    ongoingApplications: null,
    pausedApplications: null,
    historicApplications: null,
    cancelledApplications: null
  },

  getters: {
    getApplications: state => state
  },

  actions: {
    async fetchAllApplications ({ commit, dispatch }) {
      try {
        const client = new GraphQLClient(Vue.prototype.SERVICES.elGateway.replace('{country}', Vue.prototype.COUNTRY), {
          credentials: 'include',
          mode: 'cors'
        })

        const query = gql`
        query {
          Applications_myApplications {
            attributes {
              applicationStatus
              registrationDate
              startDate
            }
            applicants {
              email
              personalIdentityNumber
            }
            entities {
              property {
                address
              }
            }
            id
            product {
              cancellationPeriod
              commitmentPeriod
              validityPeriod
              provider {
                logoUrl
                name
              }
              pricing {
                totalKwhPriceInclVat
              }
              type
            }
          }
        }`

        const data = await client.request(query)
        const allApplications = getProp(data, 'Applications_myApplications')

        if (isNotEmpty(allApplications)) {
          commit('setHasApplications', true)

          const receiptBaseUrl = Vue.prototype.SERVICES.elskling.replace('{country}', Vue.prototype.COUNTRY) + `/helpers/receipt?countryCode=${Vue.prototype.COUNTRY}&brand=${Vue.prototype.BRAND}`

          commit('setOngoingApplications', allApplications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.attributes.applicationStatus) === 'ONGOING').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setPausedApplications', allApplications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.attributes.applicationStatus) === 'PAUSED').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setHistoricApplications', allApplications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.attributes.applicationStatus) === 'HISTORIC').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setCancelledApplications', allApplications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.attributes.applicationStatus) === 'CANCELLED').map(application => formatApplication(application, receiptBaseUrl)))
        }
      } catch (e) {
        console.error(e)
      }

      commit('setLoading', false)
    }
  },

  mutations: {
    setLoading (state, v) {
      if (isBool(v)) {
        state.loading = v
      }
    },
    setHasApplications (state, v) {
      if (isBool(v)) {
        state.hasApplications = v
      }
    },
    setOngoingApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.ongoingApplications = v
      }
    },
    setPausedApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.pausedApplications = v
      }
    },
    setHistoricApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.historicApplications = v
      }
    },
    setCancelledApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.cancelledApplications = v
      }
    }
  }
}
