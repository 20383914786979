export function addConnectelChat () {
  const se = document.createElement('script')
  se.type = 'text/javascript'
  se.id = 'knowledge-base'
  se.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'zmarta.kb.connectel.app/faq/widget.js?uniqueId=a08SYUfmbvCPHZ0JB1sb'

  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(se, s)

  const style = document.createElement('style')
  style.appendChild(document.createTextNode(`
    #connectel-launcher-container628 > div[id^="zoid-connectel-launcher"] {
      z-index: 20 !important;
    }
  `))

  const st1 = document.getElementsByTagName('style')[0]
  st1.parentNode.insertBefore(style, st1)
}
